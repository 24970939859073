import React, { useContext } from "react";
import Layout from "layout";
import { Context } from "redux/Store";
import Seo from "common/seo";
// import { Row, Col } from "react-grid-system";
import "./podcast.scss";

function PodcastPage({ pageContext }) {
  const { state, dispatch } = useContext(Context);
  return (
    <Layout pageClass="page-podcast" breadcrumbData={pageContext.breadcrumb}>
      <Seo pageTitle="Podcast" />
      <div className="inner-body-content">
        <section className="inner-centered-container ">
          <div>PodcastPage</div>
        </section>
      </div>
    </Layout>
  );
}

export default PodcastPage;
